/* ==========================================================================
	PROFILE LISTING MODULE
========================================================================== */

.profile-listing {
	@include clearfix;
}

.profile-listing__item {
	border-bottom: 1px solid $borderColor;
	padding: 30px 0;
	@include clearfix;

	&:first-child {
		padding-top: 0;
	}

	@include above-phone {
		display: table;
		vertical-align: middle;
	}
}

.profile-listing__image {
	margin: 0 auto 20px;
	max-width: 100%;
	width: 300px;

	@include above-phone {
		display: table-cell;
		margin: 0;
		vertical-align: top;
		width: 270px;
	}

	img {
		border: solid 1px $borderColor;
		width: 100%;
	}
}

.profile-listing__content {
	width: 100%;

	@include above-phone {
		display: table-cell;
		padding: 0 0 0 30px;
		vertical-align: middle;
		width: calc(100% - 270px);
	}

	.heading--quaternary {
		margin-top: 0;
		text-transform: uppercase;
	}

	.heading--quinary {
		font-style: italic;
	}
}
