/* ==========================================================================
	FIELD
========================================================================== */


.form-table {
	overflow-x: auto;
	width: auto;
}

.form-table__element {
	border-collapse: collapse;
	height: auto;
	width: 100%;
}

.form-table__header {
	font: {
		size: 1.2rem;
		weight: bold;
	}
	letter-spacing: 3px;
	line-height: 1.2;
	padding: 15px 15px 10px;
	text: {
		align: left;
		// Leaving commented until fully tested
		//transform: capitalize;
	}
}

.form-table__cell {
	min-width: 150px;
	padding: 0 15px 15px;
}

.duplicate-row-btn {
	background: none;
	border: none;
	bottom: 0;
	cursor: pointer;
	font: {
		size: 1.2rem;
		weight: bold;
	}
	letter-spacing: 3px;
	line-height: 1.2;
	margin: 0 15px 15px;
	position: absolute;
	text: {
		align: left;
		decoration: none;
		// Leaving commented until fully tested
		//transform: capitalize;
	}

	span {
		text-decoration: underline;
	}

	&:hover {
		span {
			text-decoration: none;
		}
	}

	&:after {
		@extend %icon;
		content: "\e910";
		margin-left: 5px;
		text-decoration: none;
	}
}

.field {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	margin-bottom: 20px;
	width: 100%;

	&:last-child {
		margin-bottom: -10px;

		@include above-phone {
			margin-bottom: 0;
		}
	}

	&--condensed {
		height: auto;
	}

	&--select {
		position: relative;

		.field__label {
			&:after {
				border: {
					bottom: solid 2px $heading;
					radius: 1px;
					right: solid 2px $heading;
				}
				bottom: 15px;
				content: '';
				display: block;
				height: 10px;
				position: absolute;
				right: 15px;
				transform: translateY(-50%) rotate(45deg);
				width: 10px;
				z-index: 2;
			}
		}

		.field__item {
			appearance: none;
			background: transparent;
			padding-right: 40px;

			&::-ms-expand {
				display: none;
			}
		}

		&.field--error .field__label {
			&:after {
				border: {
					bottom: solid 2px $error;
					right: solid 2px $error;
				}
			}
		}
	}

	&--checkbox,
	&--radio {
		display: block;
		height: auto;
		padding: 0 15px 0 0;

		&:first-of-type {
			padding-left: 0;
		}

		&:last-of-type {
			padding-right: 0;
		}

		.field__label {
			line-height: 1.3;
			padding-left: 25px;
			position: relative;
			text-align: left;

			&:before {
				background: $white;
				border: solid 1px $paragraph;
				box-shadow: inset 0px 0px 0px 2px rgba($white, 0);
				content: '';
				display: block;
				height: 15px;
				left: 0;
				position: absolute;
				top: 0;
				width: 15px;
				z-index: 2;
				transition: background $menuSpeed, box-shadow $menuSpeed;
			}
		}

		.field__item {
			height: 0;
			overflow: hidden;
			visibility: hidden;
			width: 0;

			&:checked + .field__label:before {
				background: $paragraph;
				box-shadow: inset 0px 0px 0px 2px rgba($white, 1);
			}
		}
	}

	&--radio {
		.field__label {
			&:before {
				border-radius: 100%;
			}
		}
	}
}

.field__label {
	@extend %label-title;
	display: block;
}

.field__item {
	background: $white;
	border: solid 1px $paragraph;
	border-radius: 0;
	color: $paragraph;
	display: block;
	font: {
		family: $openSans;
		size: 1.4rem;
	}
	height: 45px;
	padding: 12px 15px;
	width: 100%;

	&--large {
		height: 223px;
	}
}

.field--error {
	.field__label,
	.field__item {
		color: $error;
	}

	.field__item {
		border-color: $error;
	}
}

.grid__item--error {
	@extend .field--error;
	padding-bottom: 15px;
	position: relative;

	.field__error {
		bottom: 5px;
		left: 15px;
		position: absolute;
	}

	.field__label:before {
		border-color: $error;
	}
}

.field__error {
	@extend %text;
	color: $error;
	margin: -5px 0 10px 0;
}

.field-validation-valid {
	display: none;
}

@include phone {
	.panel-basket {
		.table {
			input[type="number"] {
				max-width: 60px;
			}
			.btn {
				font-size: 0;
				min-height: auto;
				padding: 2px 5px;

				&:before {
					content: 'x';
					display: block;
					font-size: 1.2rem;
				}
			}
		}
	}
}

.umbraco-forms-form {

	fieldset {
		border: 0;
		min-width: 0;
		padding: 20px 0;
	}

	.recaptchatable .recaptcha_image_cell {
		padding: 0 !important;
	}

	.recaptchatable #recaptcha_image {
		border: none !important;
	}

	#recaptcha_widget_div {
		margin: 0;
	}

	.umbraco-forms-field {
		@extend .field;
	}

	.umbraco-forms-label {
		@extend .field__label;
	}

	span.checkboxlist,
	.radiobuttonlist {
		margin: 0;
		padding: 0;

		label {
			@extend .field__label;
			clear: left;
			float: left;
			line-height: 1.5;
			padding-left: 25px;
			position: relative;
			text-align: left;

			&:before {
				background: $white;
				border: solid 1px $paragraph;
				box-shadow: inset 0px 0px 0px 2px rgba($white, 0);
				content: '';
				display: block;
				height: 15px;
				left: 0;
				position: absolute;
				top: 0;
				width: 15px;
				z-index: 2;
				transition: background $menuSpeed, box-shadow $menuSpeed;
			}
		}

		input[type="checkbox"],
		input[type="radio"] {
			height: 0;
			overflow: hidden;
			visibility: hidden;
			width: 0;

			&:checked + label:before {
				background: $paragraph;
				box-shadow: inset 0px 0px 0px 2px rgba($white, 1);
			}
		}
	}

	input[type="text"],
	input[type="password"] {
		@extend .field__item;
		max-width: none !important;
	}

	textarea {
		@extend .field__item;
		max-width: none !important;
		height: 223px;
	}

	input[type="submit"] {
		@extend .button;
	}

	input[type="radio"] {
		@extend .field;
		@extend .field--radio;
		margin-bottom: 0;
	}

	input[type="checkbox"] {
		@extend .field;
		@extend .field--checkbox;
		margin-bottom: 0;
		height: 12px !important;
    width: 12px !important;
	}

	.field-validation-error {
		color: $error;
		font-size: 1.4rem;
		font-weight: 500;
		letter-spacing: 0.4px;
		line-height: 1.6;
		margin: -5px 0 10px 0;
	}

	.umbraco-forms-field.dropdown {
		.umbraco-forms-field-wrapper {
			position: relative;

			.appearance & {
				@extend %icon--arrow-down;

				&:before {
					font-size: 2rem;
					pointer-events: none;
					position: absolute;
					right: 15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			label {
				&:after {
					border: {
						bottom: solid 2px $heading;
						radius: 1px;
						right: solid 2px $heading;
					}
					bottom: 15px;
					content: '';
					display: block;
					height: 10px;
					position: absolute;
					right: 15px;
					transform: translateY(-50%) rotate(45deg);
					width: 10px;
					z-index: 2;
				}
			}

			select {
				@extend .field__item;
				appearance: none;
				background: transparent;

				&::-ms-expand {
					display: none;
				}
			}

		}
	}
}
// Valentines Competition Form
#CONTACTA_61e7e0649ae6b8658cd89462 {
	.contacta-webform-form {
		width: 100% !important;
		max-width: initial !important;
	}
	.contacta-webform-table .contacta-no-margin-top {
		display: none !important;
		&:nth-child(6) {
			display: block !important;
		}
	}
	.contacta-webform-table .contacta-col {
		padding: 0 !important;
		label {
			color: #001e60;
			font-family: "co-headline-regular","Open Sans",Arial,sans-serif;
			font-size: 1.6rem;
    	font-weight: 700;
			letter-spacing: .4px;
    	line-height: 1.6;
			&.label-option {
				font-weight: 500;
			}
		}
	}

	.contacta-webform-table {
		display: grid; 
		grid-auto-columns: 1fr; 
		grid-template-columns: 1fr 1fr; 
		grid-template-rows: auto auto; 
		gap: 15px 30px; 
		grid-template-areas: 
			". ."
			". ."; 
		height: 100%; 
		@media only screen and (max-width: 550px) {
			display: block;
		}
		input:not([type="radio"]):not(.hasDatepicker):not([type="checkbox"]) {
			padding: 10px;
			font-size: 18px;
			@media only screen and (max-width: 550px) {
					width: 100%;
			}
		}
		.contacta-row {
			&:nth-child(5) {
				label {
					display: none;
					&.label-option {
						display: block;
					}
				}
			}
		}
		.checkbox-option {
			vertical-align: middle !important;
			margin-right: 10px;
		}
	}
	#contacta-webform-submission-61e7e0649ae6b8658cd89462 {
    background: #001e60 !important;
		color: #fff !important;
    border: solid 1px #001e60 !important;
    border-radius: 0 !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    line-height: 44px !important;
		float: right;
		min-width: 50% !important;
    @media only screen and (max-width: 550px) {
        width: 100% !important;
				margin-top: 40px;
				float: none;
			}
	}
	#contacta-webform-submission-61e7e0649ae6b8658cd89462:hover {
			background: #fff !important;
			border: solid 1px #001e60 !important;
			color: #001e60 !important;
			filter: none !important;
	}
	.submission-message-info {
		padding: 40px 0;
		color: #001e60;
		font-family: "co-headline-regular","Open Sans",Arial,sans-serif;
		font-size: 1.6rem;
		font-weight: 400;
		letter-spacing: .4px;
		line-height: 1.6;
	}
}