/* ==========================================================================
	ANIMATION
========================================================================== */

// All Keyframes essentially live in this partial
@include keyframes(notificationwrapper) {
	0% {
		opacity: 0;
		@include transform(translate3d(50%,0,0));
	}
	100% {
		opacity: 1;
		@include transform(translate3d(0%,0,0));
	}
}

@keyframes pulsate {
	0% {
		transform: translateY(-50%), scale(0.8, 0.8);
	}
	50% {
		transform: translateY(-50%), scale(1.2, 1.2);
	}
	100% {
		transform: translateY(-50%), scale(0.8, 0.8);
	}
}