[data-content-id="1059"], [data-content-id="3816"], [data-content-id="3778"], [data-content-id="3759"], [data-content-id="3892"], [data-content-id="10837"], [data-content-id="3911"], [data-content-id="3968"], [data-content-id="3797"], [data-content-id="3930"], [data-content-id="3949"], [data-content-id="11372"], [data-content-id="3873"], [data-content-id="3854"]{
 .section {
    blockquote {
      background-image: url(/media/6110/imo-bubbles-bg.jpg);
      color: white !important;
      padding: 50px 0 !important;
      background-position: center;
      font-family: "co-headline-bold", "Open Sans",Arial,sans-serif !important;
      font-size: 3rem !important;
      font-style: normal !important;
      text-transform: initial !important;
      &:before, &:after {
        display: none !important;
      }
    }
  }
}