/* ==========================================================================
	LANGUAGE NOTIFICATION BAR
========================================================================== */

.language-notification {
	align-items: center;
	align-content: stretch;
	background: $brandPrimaryDark;
	color: $white;
	display: none;
	flex-direction: row;
	flex-wrap: nowrap;
	font-size: 1.2rem;
	justify-content: flex-start;
	width: 100%;

	&--active {
		display: flex;
	}
}

.language-notification__content {
	flex: 1 1 auto;
	padding: 5px 10px;

	@include tablet-portrait {
		padding: 5px 45px;
	}	
}

.language-notification__flag {
	display: inline-block;
	height: 100%;
	margin: 0 5px;
}

.language-notification__link {
	color: $white;
	display: inline-block;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

.language-notification__close {
	flex: 0 0 auto;
	padding: 5px;

	@include tablet-portrait {
		padding: 5px 20px 5px;
	}
}

.language-notification__close-btn {
	@extend %icon--close;
	background: none;
	border: none;
	color: $white;
	font-size: 0;
	display: block;

	&:before {
		font-size: 2rem;
	}
}
