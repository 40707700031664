/* ==========================================================================
	TABS
========================================================================== */

.tab-nav {
  background: $navigationBg;
  display: block;
  width: 100%;

  &__list {
    display: block;
    list-style: none;
    margin: 0 auto;
    max-width: $content;
    padding: 0;
    width: 100%;

    @include above-phone {
      display: flex;
    }
  }

  &__item {
    border-bottom: solid 1px $navigationBorder;
    display: block;
    text-align: center;

    @include above-phone {
      border: {
        bottom: none;
        right: solid 1px $white;
      }

      box-flex: 1;
      display: inline-block;
      flex: 1;


      &:first-child {
        border-left: solid 1px $white;
      }
    }

    &--active {
      .tab-nav__link {
        background: $offwhite;
        color: $navigation;
      }
    }
  }

  &__link {
    background: $uiControls;
    color: $white;
    display: block;
    font-size: 1.5rem;
    height: 100%;
    padding: 10px;

    text: {
      align: center;
    }

    transition: background $menuSpeed,
    color $menuSpeed;

    @include above-phone {
      padding: 25px;
    }

    &-heading {
      display: block;
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 10px;
    }

    &-subheading {}
  }
}

.tab-content {
  display: none;
  width: 100%;

  &--active {
    display: block;
  }
}