/* ==========================================================================
	TESTIMONIALS
========================================================================== */

.testimonial-list {
	border-bottom: solid 1px $hr;
	padding: 0 15px;
}

.testimonial-list__blockquote {
	@extend %blockquote;
	margin-bottom: 0;
}

.testimonial-list__name {
	@extend %heading;
	@extend %heading--tertiary;
	margin-bottom: 15px;
}

.testimonial-list__location {
	@extend %heading;
	@extend %heading--tertiary;
	font-size: 1.225rem;
	margin-bottom: 20px;
}

.testimonial-list__item {
	border-top: solid 1px $hr;
	padding: 30px 0;
	text-align: center;
	@include clearfix;

	@include above-phone {
		padding: 60px 0;
		text-align: left;
	}
}

.testimonial-list__image {
	border: solid 3px $hr;
	border-radius: 100%;
	display: block;
	height: 100px;
	margin: 0 auto 10px;
	width: 100px;

	@include above-phone {
		float: left;
		margin: 0 30px 0 0;
	}
}

.testimonial-list__text {
	@include above-phone {
		float: left;

		.testimonial-list__image + & {
			width: calc(100% - 130px);
		}
	}
}

.promo--location-testimoinals {
	border: {
		bottom: none;
		right: none;
	}
	padding: 45px 30px 30px;

	@include respond-from(1091px) {
		padding: 45px 30px 15px;
	}

	.slick-dots {
		bottom: 10px;
		left: 0;
		max-width: 100%;

		@include respond-from(1091px) {
			bottom: 30px;
		}
	}
}