/* ==========================================================================
	SHOP STYLES
========================================================================== */

.btn {
	background: $button;
	border: none;
	border-radius: 0;
	color: $white;
	display: inline-block;
	font: {
		size: 1.4rem;
		weight: 600;
	}
	min-height: 42px;
	padding: 11px 15px;
	text: {
		align: center;
		transform: uppercase;
	}
	transition: background $menuSpeed, border $menuSpeed, color $menuSpeed;
	vertical-align: middle;
	width: auto;

	.no-touchevents & {
		&:hover {
			background: $white;
			color: $button;
		}
	}
}

.mui-basket input[type="number"] {
	@extend .field__item;
	height: auto;
	padding: 5px 15px;
	width: auto;
}

.form-control {
	@extend .field__item;
}

.panel,
.panel-heading {
	border-radius: 0;
}

.container-fluid {
	padding: 0;
}

.alert {
	border-radius: 0;
}

.alert-info {
	color: $white;
	background: rgba($black, 0.5);
	border-color: $black;
}

// Overwrite existing shop theme styles.
.alert-success {
	border: dashed 1px $uiBorder;
	margin-top: 10px;
	padding: 10px;
	@include clearfix;

	.close {
		background: $button;
		border: none;
		border-radius: 0;
		color: $white;
		display: inline-block;
		float: right;
		font: {
			size: 1.2rem;
			weight: bold;
		}
		padding: 2px 5px;
	}
}

// New shop styles
.table-striped {
	border: {
		left: solid 1px $mapContentActive;
		top: solid 1px $mapContentActive;
	}
	font-size: 1.4rem;
	width: 100%;

	td,
	th {
		border: {
			bottom: solid 1px $mapContentActive;
			right: solid 1px $mapContentActive;
		}
		padding: 10px;
		vertical-align: top;
	}
}

.table-striped--basket {
	th:not([class]) {
		width: 50px;
	}

	.span2 {
		width: 110px;
	}

	.span1 {
		width: 15%;
	}
}

form {
	@include clearfix;
}

.line-remove {
	background: $button;
	border: none;
	border-radius: 0;
	color: $white;
	display: block;
	font: {
		size: 1.2rem;
		weight: bold;
	}
	margin: 0 auto;
	padding: 2px 5px;
}

.qty {
	width: 90px;
}
.pull-xs-right {
	padding: 20px 0;
}

.input-group {
	margin: 0 0 20px 0;
	width: 100%;
	@include clearfix;

	.form-control {
		float: left;
		margin-right: 10px;
		width: 140px;
	}
	.input-group-btn {
		width: calc(100% - 120px);
	}
}

.btn-secondary {
	min-height: 45px;
}

.form-overflow {
	overflow: auto;
}

.address-line abbr {
	margin-right: 5px;
}