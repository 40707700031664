/* ==========================================================================
	FOOTER
========================================================================== */

.footer {
	background-color: transparent;
	height: auto;
	margin-top: 0;
	padding-top: 0;
	text-transform: uppercase;
}

.footer__list {
	list-style: none;
	margin: 0;
	padding: 20px 10px;
	position: relative;

	@include above-phone {
		padding: 30px;
	}
}

.footer__list--primary {
	background: $white;
	position: relative;

	// Required mobile only styles
	@include phone {
		.footer__item {
			display: table;
			letter-spacing: 2px;
			margin: 20px auto;
		}

		.footer__item--right {
			margin-bottom: 0; 
			position: static;
			transform: translateY(0);
		}

		.footer__item--breakout {
			margin-top: 0;
		}
	}

	@include tablet {
		text-align: center;

		.footer__item--right {
			display: block;
			margin-top: 30px;
			position: static;
			text-align: center;
			transform: translateY(0);
		}
	}

	@include above-phone {
		.footer__item {
			border-left: solid 1px $navigation;
			font-size: 1.2rem;
			padding: 5px 10px 5px 10px;

			&:nth-child(-n+2),
			&:last-child {
				border-left: none;
				padding-left: 0;
			}
		}
	}

	@include respond-from($desktop) {
		padding-right: 160px;

		.footer__item--right {
			position: absolute;
			right: 30px;
			bottom: 30px;
		}
	}

	@include large-desktop {
		.footer__item {
			padding: 0 10px;
		}

		.footer__item--right {
			margin-top: 0;
		}
	}
}

.footer__list--secondary {
	background: #001e60;

	.footer__item {
		padding: 0 10px;
	}

	.footer__item--right {
		right: 10px;
	}

	@include above-phone {
		.footer__item--right {
			right: 30px;
		}
	}
}

.footer .carwashlogo-grid{
	text-align:center;
	@include respond-from($desktop) {
		text-align:left;
	}
}

.footer .carwash-logo {
	max-width:15%;
	display:inline-block;
	vertical-align: middle;
	@include respond-from($desktop) {
		max-width:10%;
	}
	@include respond-from($largeDesktop) {
		max-width:4.5%;
	}
}

.footer .carwash-logo-text {
	display:inline-block;
	text-transform: none;
	text-align:center;
	vertical-align: middle;
	width:73%;
	font-size:12px;
	color:#6b6b6a;
	position:relative;
	margin-bottom:0px;
	@include respond-from($tabletPortrait) {
		margin-left:0%;
	}
	@include respond-from($desktop) {
		text-align:left;
		display:inline-block;
		width: 67%;
	}
	@include respond-from($largerDesktop) {
		width:53%;
	}
	@include respond-from($resp2130) {
		width:41%;
	}
}

.footer__item {
	color: $navigation;
	display: inline-block;
	font-size: 1.4rem;
	line-height: 1;
	margin: 0;
	vertical-align: middle;

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}

	&--breakout {
		display: inline-block;

		@include tablet {
			justify-content: center;
		}

		@include respond-from($phone) {
			display: flex;
		}
	}
}

.footer__link {
	color: $navigation;
	display: block;
	text-align: center;

	@include respond-from($phone) {
		display: inline-block;
		text-align: left;
	}

	.no-touchevents & {
		&:hover {
			text-decoration: underline;
		}	
	}
}

.footer__controls {
	border: solid 1px $white;
	color: $white;
	display: block;
	line-height: 1;
	padding: 10px;
	text-transform: capitalize;

	.no-touchevents & {
		transition: background $menuSpeed, border $menuSpeed, color $menuSpeed;

		&:hover {
			background: $white;
			border: solid 1px $footer;
			color: $footer;
		}
	}

	&--social {
		font-size: 0;

		&:before {
			font-size: 1.7rem;
		}
	}

	&--facebook {
		@extend %icon--facebook;
	}

	&--twitter {
		@extend %icon--twitter;
	}

	&--to-top {
		letter-spacing: 2px;
		line-height: 1.25;
		padding: {
			left: 20px;
			right: 20px;
		}

		@include above-phone {
			padding: {
				left: 40px;
				right: 40px;
			}
		}
	}
}
