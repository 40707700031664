/* ==========================================================================
	MAP ELEMENTS USED IN MULTIPLE AREAS
========================================================================== */

.map-toggle {
	display: none;

	@include above-phone {
		color: $uiControls;
		cursor: pointer;
		display: inline-block;
		float: right;
		opacity: 1;
		z-index: 4;
	}

	@include respond-to-from($phone, $resp940) {
		top: 140px;
	}

	&--close {
		@extend %icon--close;
		background: none;
		border: none;
		font-size: 2.6rem;
		transition: opacity $menuSpeed;

		.search-results__details & {
			margin: 15px 0 0 0;
		}

		.location--details-hide &,
		.search-results--details-hide & {
			opacity: 0;
			right: -20px;
		}

		@include above-phone {
			position: absolute;
			right: 15px;
			top: 130px;
			z-index: 100;
		}
	}

	&--open {
		@extend %icon--circle-right;
		background: $mapContentBg;
		border: {
			color: $borderColor;
			style: solid;
			width: 1px 1px 1px 0;
		}
		font-size: 0rem;
		left: calc(0% - 100px);
		padding: 10px;
		position: absolute;
		top: 170px;
		transition: left $menuSpeed;

		&:before {
      color: $brandPrimary;
      font-size: 4rem;
		}

		.location--details-hide &,
		.search-results--details-hide & {
			left: 0;
		}
	}
}

// Added class to position zoom control
.control-wrapper {
	bottom: 0;
	left: calc(100vw - 10px);
	position: absolute;
}