

.rating {
  text-align: center;
  margin:  0 0 20px 0;

  &__item {
    color: $gold;
    display: inline-block;


    &::before {
      @extend %icon--star-before;
    }

    &--open {
      &::before {
        @extend %icon--star-o-before;
      }
    }
  }
}