.services {

  $this: &;

  .heading-eyebrow {
    display: inline-block;
  }

  &__list {

    @include above-phone {
      &--2-cols {
        margin: 0 -10px;

        #{$this}__item {
          width: calc(50% - 20px);
          margin: 10px 10px 15px 10px;
          float: left;
        }
      }
    }

  }

  &__item {
    list-style-type: none;
    margin-bottom: 15px;
  }

  &__name {
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
    margin: 0;
  }

  &__description {
    display: block;
    font-size: 16px;
    font-weight: normal;
  }

  &__key {
    display: block;
    margin: 0 0 30px 0;

    @include above-phone {
      display: inline-block;
      margin: 0;
    }


  }

  &__item-key {
    display: inline-block;
    margin: 0 5px;
    line-height: 1;
    font-size: 16px;
    position: relative;
    width: 17px;
    height: 17px;

    &::before {
      display: inline-block;
      line-height: 1;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }

    &:first-child {
      @include above-phone {
        margin-left: 20px;
      }
    }

    &--with-label {
      width: auto;
      padding: 0 0 0 30px;
      height: auto;
      margin: 0 10px;
    }

    &--platinum {
      &::before {
        content: '';
        display: block;
        width: 9px;
        height: 9px;
        background: $platinum;
        transform: rotate(45deg) translate(0, -50%);
      }
    }

    &--gold {
      &::before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 9px 9px 9px;
        border-color: transparent transparent $gold transparent;
        transform: translate(-2px, calc(-50% + 1px));
      }
    }

    &--silver {
      &::before {
        content: '';
        border-radius: 100%;
        display: block;
        width: 12px;
        height: 12px;
        background: $silver;
        transform: translate(0, -50%);
      }
    }



  }
}