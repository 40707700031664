/* ==========================================================================
	SLIDER
========================================================================== */

.slider {
  opacity: 0;
  transition: opacity $transitionSpeed * 2, transform $transitionSpeed * 2;

  &.slick-initialized {
    opacity: 1;
    padding-bottom: 30px;
  }

  &--flex {
    .slick-track {
      display: flex;
      margin: 0 -10px;
      width: 100%;
    }

    .slider__slide {
      align-items: center; //optional
      display: flex;
      height: auto;
      justify-content: center; //optional
      padding: 0 10px;
      position: relative;
    }

    .slick-dots {
      display: block;
      padding: 20px 0;
      text-align: center;

      button {
        background: rgba($white, 1);
        border-color: rgba($brandPrimary, 1);
      }

      .slick-active button {
        background: $brandPrimary;
      }
    }
  }
}

.slick-dots {
  display: inline-block;
  font-size: 0;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 5px;
    padding: 0;
  }

  button {
    background: rgba($white, 1);
    border: solid 2px $white;
    border-radius: 100%;
    display: block;
    font-size: 0;
    height: 11px;
    transition: background $menuSpeed;
    width: 11px;
  }
}

.slick-active button {
  background: rgba($white, 0);
}
