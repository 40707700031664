/* ==========================================================================
	PRINT STYLES
========================================================================== */

@media print {

	.button {
		background: none;
		border: solid 1px $paragraph;
		color: $paragraph;
	}

	.header {
		position: static;
	}

	.heading--banner {
		color: $paragraph;
	}

	.heading--banner:last-child {
		margin-top: 0;
	}

	.banner {
		min-height: 10px;
		padding-top: 20px;
	}
	.grid,
	.grid__item,
	.field {
		display: block;
		flex: initial!important;
		flex-direction: initial!important;
		flex-wrap: initial!important;
		float: none!important;
		height: auto!important;
		justify-content: initial!important;
		max-width: 100%!important;
		position: static!important;
		width: 100%!important;
	}

	.grid__item,
	.field {
		page-break-inside: avoid!important;
		-webkit-region-break-inside: avoid!important;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		page-break-after: avoid!important;
	}

	.g-recaptcha,
	.footer__item,
	.header .feature-button,
	.header__bar,
	.grid__item--submit,
	.grid--submit,
	.footer__list--secondary,
	.country-selector,
	.footer__item--breakout {
		display: none!important;
	}

	.footer__item:nth-child(2) {
		display: inline-block !important;
	}

	.section {
		padding: 20px 0 0 0;
	}
}