/* ==========================================================================
	ANCHOR NAV
========================================================================== */


.anchor-nav {
  background: $navigationBg;
  box-shadow: 1.5px 2.598px 7px 0 rgba($black, 0.2);
  display: block;
  width: 100%;
}

.anchor-nav--sticky {
  position: static;

  @include above-phone {
    left: 50%;
    position: fixed;
    top: 110px;
    transform: translateX(-50%);
    z-index: 100;
  }
}


.anchor-nav__list {
  display: block;
  list-style: none;
  margin: 0 auto;
  max-width: $content;
  padding: 0;
  width: 100%;

  @include above-phone {
    display: flex;
  }

  @include desktop {
    padding: 0 15px;
  }
}


.anchor-nav__item {
  border-bottom: solid 1px $navigationBorder;
  display: block;
  text-align: center;

  @include above-phone {
    border: {
      bottom: none;
      right: solid 1px $navigationBorder;
    }
    box-flex: 1;
    display: inline-block;
    flex: 1;


    &:first-child {
      border-left: solid 1px $navigationBorder;
    }
  }

  &--active {
    .tab-nav__link {
      background: darken($navigationBg, 10%);
    }
  }
}

.anchor-nav__link {
  background: $navigationBg;
  color: $navigationColor;
  display: block;
  font-size: 1.5rem;
  height: 100%;
  padding: 5px;
  text: {
    align: center;
    transform: uppercase;
  }
  transition: background $menuSpeed, color $menuSpeed;


  &:hover {
    background: $navigationColor;
    color: $navigationBg;
  }

  @include above-phone {
    padding: 25px;
  }
}
