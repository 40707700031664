// Styles for Generic page with list template
.generic-full-width-page-header {
  padding: 30px 15px;
  p {
    text-align: center;
    color: #001E60;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.4;
    margin: 0;
    padding: 0;
  }
}
.generic-full-width-page-list {
  padding: 15px;
  background-color: #BEE9FF;
  .generic-full-width-page-list-item {
    @media only screen and (min-width: 768px) {
      display: grid; 
      grid-auto-columns: 1fr; 
      grid-template-columns: 75px 1fr; 
      grid-template-rows: 1fr; 
      gap: 30px 30px; 
      grid-template-areas: 
        ". ."; 
      height: 100%;
      align-items: start;
      max-width: 860px;
      margin: auto;
    }
  }
  img {
    display: block;
    margin: auto;
    max-width: 75px;
    height: auto;
  }
  .generic-full-width-page-list-copy {
    padding: 15px 0;
    @media only screen and (min-width: 768px) {
      padding: 0 0 15px 0;
    }
    h2 {
      text-align: center;
      color: #001E60;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 1.6;
      margin: 0;
      padding: 0;
      @media only screen and (min-width: 768px) {
        text-align: left;
      }
    }
    p {
      text-align: center;
      color: #001E60;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.4;
      margin: 0 0 20px 0;
      padding: 0;
      @media only screen and (min-width: 768px) {
        text-align: left;
      }
    }
    ul {
      margin-left: 15px;
      li {
        text-align: left;
        color: #001E60;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.4;
        margin: 0 0 10px 0;
        padding: 0;
      }
    }
  }
}
.generic-full-width-page-promo {
  padding: 15px;
  display: grid;
  @media only screen and (min-width: 768px) {
    grid-auto-columns: 1fr; 
    grid-template-columns: 300px 1fr; 
    grid-template-rows: 1fr; 
    gap: 15px 30px; 
    grid-template-areas: 
      ". ."; 
    height: 100%;
    max-width: 860px;
    margin: auto;
    padding: 60px 0;
  }
  img {
    margin: auto;
    display: block;
  }
  .generic-full-width-page-promo-content {
    padding: 15px 0;
    @media only screen and (min-width: 768px) {
      padding: 0;
    }
  }
  h2 {
    text-align: left;
    color: #001E60;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.6;
    margin: 0 0 10px 0;
    padding: 0;
  }
  p {
    text-align: left;
    color: #001E60;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.4;
    margin: 0 0 20px 0;
    padding: 0;
    &.generic-full-width-page-promo-content-terms {
      font-size: 1.3rem;
    }
  }
}
.generic-full-width-page-promo-content-app-icons {
  display: grid; 
  grid-auto-columns: 1fr; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 15px 15px; 
  grid-template-areas: 
    ". ."; 
  height: 100%;
  max-width: 350px;
  margin: auto;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}