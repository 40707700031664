.review-testimonial {
  display: block;
  max-width: 320px;
  margin: auto;

  &__item {}

  &__blockquote {
    @extend %heading--tertiary;
    color: $black;
    letter-spacing: 0;
    font-weight: 600;
    margin-bottom: 0;
    position: absolute;
    top: 50%;
    display: block;
    text-align: center;
    padding: 16px;
    transform: translate(0, -50%);
    min-width:320px;
    max-width:320px;
  }
}