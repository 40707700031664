/* ==========================================================================
	FEATURE LIST
========================================================================== */

.feature-list {
	color: $heading;
  font: {
  	size: 1.2rem;
  	weight: 600;
  }
  line-height: 1.4;
  margin-left: 15px;
  text-transform: uppercase;

  li {
  	margin-bottom: 8px;

  	&:last-child {
  		margin-bottom: 20px;
  	}
  }
}